<template>
  <span class="terms__agree">
    <IconComponent name="tick-circle" />
    <span>
      Согласен с
      <router-link :to="{ name: 'static', params: { id: 5 } }">условиями пользования</router-link> и
      <router-link :to="{ name: 'static', params: { id: 6 } }">правилами возврата</router-link>
    </span>
  </span>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "TermsAgreeComponent",
  components: { IconComponent },
};
</script>

<style lang="stylus">
.terms__agree {
  margin-top: 10px;
  color var(--brown)
  display flex
  font-size: 0.75em
  line-height: 18px;
  gap 5px

  a {
    text-decoration underline
  }

  .icon {
    width 12px
    height 12px
    margin-top: 2px;

    svg path {
      stroke var(--brown-light)
    }
  }
}
</style>
