<template>
  <div class="cart-page page">
    <BreadcrumbsComponent @back.prevent title="Корзина" />
    <div class="cart-page__container">
      <div class="container-padding">
        <div v-if="cartItems && cartItems.length" class="cart-page__content">
          <div class="cart-page__body">
            <div class="cart-page__products">
              <ProductCardCartVersionComponent
                v-for="(product, index) in cartItems"
                :key="index"
                :data="product"
                @countChange="countChange"
                @remove="remove"
              />
            </div>
            <div class="cart-page__dropdowns">
              <FormItemComponent title="Данные покупателя">
                <div ref="recipientForm" class="form">
                  <div class="form__row">
                    <div class="form__col-4">
                      <InputComponent
                        v-model="recipientForm.surname.value"
                        title="Фамилия"
                        :subtitle="recipientForm.surname.message"
                        :error="recipientForm.surname.error"
                        modified
                        required
                      />
                    </div>
                    <div class="form__col-4">
                      <InputComponent
                        v-model="recipientForm.name.value"
                        title="Имя"
                        :subtitle="recipientForm.name.message"
                        :error="recipientForm.name.error"
                        modified
                        required
                      />
                    </div>
                    <div class="form__col-4">
                      <InputComponent
                        v-model="recipientForm.patronymic.value"
                        title="Отчество"
                        :subtitle="recipientForm.patronymic.message"
                        :error="recipientForm.patronymic.error"
                        modified
                      />
                    </div>
                    <div class="form__col-6">
                      <InputComponent
                        v-model="recipientForm.phone.value"
                        title="Телефон"
                        mask="+7 (###) ### - ## - ##"
                        :subtitle="recipientForm.phone.message"
                        :error="recipientForm.phone.error"
                        modified
                        required
                        is-phone
                      />
                    </div>
                    <div class="form__col-6">
                      <InputComponent
                        v-model="recipientForm.email.value"
                        title="Email"
                        :subtitle="recipientForm.email.message"
                        :error="recipientForm.email.error"
                        modified
                        required
                      />
                    </div>
                  </div>
                </div>
                <CheckboxComponent :checked="subscription" @change="subscription = !subscription">
                  <span> Хочу получать наши Спецпредложения и Акции </span>
                </CheckboxComponent>
              </FormItemComponent>
              <FormItemComponent title="Способ доставки">
                <div class="cart-page__methods">
                  <RadioComponent
                    v-for="(item, i) in deliveryList"
                    :key="i"
                    :checked="isChecked(item, 'delivery_type_id')"
                    @change="changeRadio($event, item, 'delivery_type_id')"
                    :hideState="true"
                  >
                    <MethodComponent
                      :class="{ 'method--active': isChecked(item, 'delivery_type_id') }"
                      :title="item.title"
                      :icon="item.icon"
                      :description="item.price_text"
                    />
                  </RadioComponent>
                </div>
                <div v-if="isPickup" class="cart-page__shop">
                  <SelectComponent
                    v-model="deliveryForm.shop_id.value"
                    options="filials"
                    :errors="deliveryForm.shop_id.error ? [deliveryForm.shop_id.error] : []"
                    :searchable="false"
                    label-name="address"
                    title="Выбор магазина"
                    selectTitle="Выберите пункт выдачи"
                    :clearable="false"
                  />
                </div>
              </FormItemComponent>
              <FormItemComponent v-if="!isPickup" title="Регион доставки">
                <div ref="deliveryForm" class="form">
                  <div class="form__row">
                    <div class="form__col-4">
                      <InputComponent
                        v-model="deliveryForm.city.value"
                        title="Город"
                        :subtitle="deliveryForm.city.message"
                        :error="deliveryForm.city.error"
                        modified
                        required
                      />
                    </div>
                    <div class="form__col-4">
                      <InputComponent
                        v-model="deliveryForm.index.value"
                        title="Индекс"
                        mask="######"
                        :subtitle="deliveryForm.index.message"
                        :error="deliveryForm.index.error"
                        modified
                        required
                      />
                    </div>
                  </div>
                </div>
              </FormItemComponent>
              <FormItemComponent v-if="!isPickup" title="Адрес доставки">
                <div class="form">
                  <div class="form__row">
                    <div class="form__col-4">
                      <InputComponent
                        v-model="deliveryForm.address.value"
                        title="Адрес"
                        :subtitle="deliveryForm.address.message"
                        :error="deliveryForm.address.error"
                        modified
                        required
                      />
                    </div>
                    <div class="form__col-4">
                      <InputComponent
                        v-model="deliveryForm.house.value"
                        title="Дом"
                        :subtitle="deliveryForm.house.message"
                        :error="deliveryForm.house.error"
                        modified
                        required
                      />
                    </div>
                    <div class="form__col-4">
                      <InputComponent
                        v-model="deliveryForm.flat.value"
                        title="Квартира"
                        :subtitle="deliveryForm.flat.message"
                        :error="deliveryForm.flat.error"
                        modified
                      />
                    </div>
                  </div>
                </div>
              </FormItemComponent>
              <FormItemComponent title="Способ оплаты">
                <div class="cart-page__methods">
                  <RadioComponent
                    v-for="(item, i) in payments"
                    :key="i"
                    :checked="isChecked(item, 'payment_type_id')"
                    @change="changeRadio($event, item, 'payment_type_id')"
                    :hideState="true"
                  >
                    <MethodComponent
                      :class="{ 'method--active': isChecked(item, 'payment_type_id') }"
                      :title="item.title"
                      :icon="item.icon"
                      :description="item.description"
                    />
                  </RadioComponent>
                </div>
              </FormItemComponent>
              <FormItemComponent title="Дополнительная информация">
                <TextareaComponent
                  v-model="deliveryForm.commentary.value"
                  title="Комментарий к заказу"
                  :subtitle="deliveryForm.commentary.message"
                  :error="deliveryForm.commentary.error"
                  modified
                />
              </FormItemComponent>
            </div>
          </div>
          <CartPageAsideComponent
            :loading="loading"
            :delivery="deliveryForm.delivery_type_id.value"
            :sum="orderSum"
            :discount="discount"
            :price_delivery="priceDelivery"
            @pay="orderCreate"
            @setPromocode="setPromocode"
            @removePromocode="removePromocode"
          />
        </div>
        <span v-else>Корзина пуста</span>
      </div>
    </div>
  </div>
</template>

<script>
import CartPageAsideComponent from "./components/AsideComponent.vue";
import MethodComponent from "./components/MethodComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import RadioComponent from "components/inputs/RadioComponent.vue";
import ORDER_CREATE from "@/graphql/mutations/OrderCreate.graphql";
import LoginModal from "components/modals/components/LoginModal.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ProductsTableComponent from "components/ProductsTableComponent.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import TextareaComponent from "components/inputs/TextareaComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";
import FormItemComponent from "components/FormItemComponent.vue";
import ProductCardCartVersionComponent from "components/product/ProductCardCartVersionComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
// import PRICE_DELIVERY from "@/graphql/queries/price_delivery.graphql";
import { removeCartItems } from "@/utils";

export default {
  name: "CartPage",
  async asyncData({ apollo, store }) {
    await store.dispatch("GET_CART_ITEMS", {
      apollo: apollo.defaultClient,
    });
  },
  data() {
    return {
      loading: false,
      debounceTimeout: null,
      // price_delivery: 0,
      subscription: true,
      payments: [
        {
          title: "Оплата картой онлайн",
          icon:
            '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '  <path d="M18.3333 6.29171C18.3333 6.84171 17.8833 7.29171 17.3333 7.29171H2.66667C2.11667 7.29171 1.66667 6.84171 1.66667 6.29171V6.28337C1.66667 4.37504 3.20833 2.83337 5.11667 2.83337H14.875C16.7833 2.83337 18.3333 4.38337 18.3333 6.29171Z" fill="#778CA7"/>\n' +
            '  <path d="M1.66667 9.54163V13.7166C1.66667 15.625 3.20833 17.1666 5.11667 17.1666H14.875C16.7833 17.1666 18.3333 15.6166 18.3333 13.7083V9.54163C18.3333 8.99163 17.8833 8.54163 17.3333 8.54163H2.66667C2.11667 8.54163 1.66667 8.99163 1.66667 9.54163ZM6.66667 14.375H5C4.65833 14.375 4.375 14.0916 4.375 13.75C4.375 13.4083 4.65833 13.125 5 13.125H6.66667C7.00833 13.125 7.29167 13.4083 7.29167 13.75C7.29167 14.0916 7.00833 14.375 6.66667 14.375ZM12.0833 14.375H8.75C8.40833 14.375 8.125 14.0916 8.125 13.75C8.125 13.4083 8.40833 13.125 8.75 13.125H12.0833C12.425 13.125 12.7083 13.4083 12.7083 13.75C12.7083 14.0916 12.425 14.375 12.0833 14.375Z" fill="#778CA7"/>\n' +
            "</svg>",
          type: this.$store.state._types.ONLINE_CARD_PAY,
        },
        {
          title: "Картой при получении",
          icon:
            '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '  <path d="M18.3333 6.29171C18.3333 6.84171 17.8833 7.29171 17.3333 7.29171H2.66667C2.11667 7.29171 1.66667 6.84171 1.66667 6.29171V6.28337C1.66667 4.37504 3.20833 2.83337 5.11667 2.83337H14.875C16.7833 2.83337 18.3333 4.38337 18.3333 6.29171Z" fill="#778CA7"/>\n' +
            '  <path d="M1.66667 9.54163V13.7166C1.66667 15.625 3.20833 17.1666 5.11667 17.1666H14.875C16.7833 17.1666 18.3333 15.6166 18.3333 13.7083V9.54163C18.3333 8.99163 17.8833 8.54163 17.3333 8.54163H2.66667C2.11667 8.54163 1.66667 8.99163 1.66667 9.54163ZM6.66667 14.375H5C4.65833 14.375 4.375 14.0916 4.375 13.75C4.375 13.4083 4.65833 13.125 5 13.125H6.66667C7.00833 13.125 7.29167 13.4083 7.29167 13.75C7.29167 14.0916 7.00833 14.375 6.66667 14.375ZM12.0833 14.375H8.75C8.40833 14.375 8.125 14.0916 8.125 13.75C8.125 13.4083 8.40833 13.125 8.75 13.125H12.0833C12.425 13.125 12.7083 13.4083 12.7083 13.75C12.7083 14.0916 12.425 14.375 12.0833 14.375Z" fill="#778CA7"/>\n' +
            "</svg>",
          type: this.$store.state._types.OFFLINE_CARD_PAY,
        },
        {
          title: "Наличными при получении",
          icon:
            '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '  <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" fill="#778CA7"/>\n' +
            '  <path d="M14.1667 2.91663H5.83332C3.33332 2.91663 1.66666 4.16663 1.66666 7.08329V7.49996V12.5V12.9166C1.66666 15.8333 3.33332 17.0833 5.83332 17.0833H14.1667C16.6667 17.0833 18.3333 15.8333 18.3333 12.9166V12.5V7.49996V7.08329C18.3333 4.16663 16.6667 2.91663 14.1667 2.91663ZM5.83332 15.8333C5.83332 13.4833 5.07499 12.6166 2.91666 12.5166V12.5V7.49996V7.48329C5.07499 7.38329 5.83332 6.51663 5.83332 4.16663H14.1667C14.1667 6.51663 14.925 7.38329 17.0833 7.48329V7.49996V12.5V12.5166C14.925 12.6166 14.1667 13.4833 14.1667 15.8333H5.83332Z" fill="#778CA7"/>\n' +
            "</svg>",
          type: this.$store.state._types.OFFLINE_CASH_PAY,
        },
      ],
      // shops: [
      //   {
      //     id: 1,
      //     title: "ул. Восстания, дом 1, магазин 1",
      //   },
      //   {
      //     id: 2,
      //     title: "ул. Восстания, дом 1, магазин 1",
      //   },
      // ],
      deliveries: [
        {
          title: "Самовывоз",
          icon:
            '<svg fill="none" viewBox="0 0 20 20" height="20" width="20" xmlns="http://www.w3.org/2000/svg">\n' +
            '  <path xmlns="http://www.w3.org/2000/svg" d="M17.1833 7.04165C16.3083 3.19165 12.95 1.45831 10 1.45831C10 1.45831 10 1.45831 9.99167 1.45831C7.05 1.45831 3.68334 3.18331 2.80834 7.03331C1.83334 11.3333 4.46667 14.975 6.85 17.2666C7.73334 18.1166 8.86667 18.5416 10 18.5416C11.1333 18.5416 12.2667 18.1166 13.1417 17.2666C15.525 14.975 18.1583 11.3416 17.1833 7.04165ZM10 11.2166C8.55 11.2166 7.375 10.0416 7.375 8.59165C7.375 7.14165 8.55 5.96665 10 5.96665C11.45 5.96665 12.625 7.14165 12.625 8.59165C12.625 10.0416 11.45 11.2166 10 11.2166Z" fill="#0102F6"></path>\n' +
            "</svg>",
          price_text: "0",
          type_id: this.$store.state._types.PICKUP,
        },
        // {
        //   title: "Курьерская доставка",
        //   icon: "truck",
        //   description: "от 500 ₽",
        //   type: this.$store.state._types.COURIER_DELIVERY,
        // },
        // {
        //   title: "Почта России",
        //   icon: "box",
        //   description: "от 500 ₽",
        //   type: this.$store.state._types.POST_DELIVERY,
        // },
      ],
      deliveryForm: {
        shop_id: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        address: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: true,
        },
        payment_type_id: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        delivery_type_id: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        location: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        city: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: true,
        },
        index: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: true,
        },
        street: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        house: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: true,
        },
        flat: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        entrance: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        code: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        floor: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        commentary: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        promocode: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
      },
      recipientForm: {
        name: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        surname: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        patronymic: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        phone: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        email: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
      },
    };
  },
  watch: {
    "$store.state.auth.user"() {
      this.setUserData();
    },
    "$route.name"() {
      this.removePromocode();
    },
    "$route.params"() {
      this.removePromocode();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.deliveries && this.deliveries.length) {
        this.deliveryForm.delivery_type_id.value = this.deliveries[0];
        this.deliveryForm.payment_type_id.value = this.payments[0];
        this.getPriceDelivery();
      }
      this.$nextTick(() => {
        this.deliveryForm.shop_id.value = {
          id: this.filials[0].id,
          address: this.filials[0].address,
        };
      });
      this.removePromocode();
    });
    if (this.isRegistered) {
      this.setUserData();
    }
  },
  computed: {
    cartItems() {
      return this.$store.state.auth.cart;
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.user;
    },
    isPickup() {
      return this.deliveryForm.delivery_type_id.value?.type_id === this.$store.state._types.PICKUP;
    },
    deliveryList() {
      return [...this.deliveries, ...this.$store.state.auth.deliveries];
    },
    priceDelivery() {
      return this.deliveryForm.delivery_type_id.value?.price_text;
    },
    filials() {
      return this.$store.state.global.branches;
    },
    // deliveries() {
    //   return this.$store.state.auth.deliveries;
    // },
    user() {
      return this.$store.state.auth.user;
    },
    orderSum() {
      return this.cartItems
        .map((r) => this.$options.filters.share_price(r.item?.price, r.item) * r.count)
        .reduce((a, b) => a + b, 0);
    },
    discount() {
      return (
        this.cartItems.map((r) => parseFloat(r.item?.price) * r.count).reduce((a, b) => a + b, 0) -
        this.orderSum
      );
    },
  },
  methods: {
    removeCartItems,
    /**
     * Вывод скидки по промокоду
     */
    setPromocode(share, promocode) {
      this.deliveryForm.promocode.value = promocode;
      let promoProducts = share.products.map((p) => p.id);
      this.$store.state.auth.cart.forEach((c) => {
        if (promoProducts.includes(c.item?.id)) {
          c.item.promo_share = { share };
        }
      });
      // костыль, изменяем массив, чтобы вызвался watch в App.vue
      // и сохранил изменения в localStorage
      this.$store.state.auth.cart.push({});
      this.$store.state.auth.cart.pop();
    },
    /**
     * Удаление промокода
     */
    removePromocode() {
      this.$store.state.auth.cart.forEach((c) => {
        delete c.item.promo_share;
      });
      // костыль, изменяем массив, чтобы вызвался watch в App.vue
      // и сохранил изменения в localStorage
      this.$store.state.auth.cart.push({});
      this.$store.state.auth.cart.pop();
    },
    /**
     * Подставить личные данные
     */
    setUserData() {
      if (this.user) {
        this.recipientForm.name.value = this.user.name;
        this.recipientForm.surname.value = this.user.surname;
        this.recipientForm.patronymic.value = this.user.patronymic;
        this.recipientForm.email.value = this.user.email;
        this.recipientForm.phone.value = this.user.phone;
      }
    },
    /**
     * Оплата
     */
    orderCreate() {
      if (this.isRegistered) {
        if (!this.loading) {
          this.loading = true;
          this.resetErrors("deliveryForm");
          this.resetErrors("recipientForm");
          let errorsCount = 0;
          if (!this.isPickup) {
            Object.keys(this.deliveryForm).forEach((key) => {
              if (this.deliveryForm[key].required && !this.deliveryForm[key].value) {
                this.deliveryForm[key].error = "Заполните поле";
                errorsCount += 1;
              }
            });
          }
          if (!errorsCount) {
            this.$apollo
              .mutate({
                mutation: ORDER_CREATE,
                variables: {
                  name: this.recipientForm.name.value,
                  surname: this.recipientForm.surname.value,
                  phone: this.recipientForm.phone.value
                    ? "+" + this.recipientForm.phone.value.replace(/\D+/g, "")
                    : undefined,
                  email: this.recipientForm.email.value,
                  commentary: this.deliveryForm.commentary.value,
                  // promocode: this.deliveryForm.promocode.value,
                  address: [
                    this.deliveryForm.city.value ? "город " + this.deliveryForm.city.value : null,
                    this.deliveryForm.street.value ? "улица " + this.deliveryForm.street.value : null,
                    this.deliveryForm.house.value ? "дом " + this.deliveryForm.house.value : null,
                    this.deliveryForm.entrance.value ? "подъезд " + this.deliveryForm.entrance.value : null,
                    this.deliveryForm.code.value ? "код " + this.deliveryForm.code.value : null,
                    this.deliveryForm.floor.value ? "этаж " + this.deliveryForm.floor.value : null,
                    this.deliveryForm.flat.value ? "квартира " + this.deliveryForm.flat.value : null,
                  ]
                    .filter((i) => i)
                    .join(", "),
                  delivery_type_id: this.isPickup ? this.deliveryForm.delivery_type_id.value.type_id : 2,
                  payment_type_id: this.deliveryForm.payment_type_id.value
                    ? this.deliveryForm.payment_type_id.value.type
                    : null,
                  filial_id: this.isPickup
                    ? this.deliveryForm.shop_id.value
                      ? this.deliveryForm.shop_id.value.id
                      : undefined
                    : undefined,
                  delivery_id: this.isPickup ? undefined : this.deliveryForm.delivery_type_id.value.id,
                },
                context: {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.apollo_token,
                  },
                },
              })
              .then(({ data }) => {
                this.loading = false;
                // проверка, выбрал ли пользователь оплату онлайн
                // лучше переделать на проверку на наличие ссылки,
                // т.к. способ поменяется если он выберет другой после клика "оформить заказ"
                // попросить бэк не возвращать ссылку если это не оплата онлайн

                // if (
                //   this.deliveryForm.payment_type_id.value &&
                //   this.deliveryForm.payment_type_id.value.type === this.$store.state._types.ONLINE_CARD_PAY
                // ) {
                if (data && data.OrderCreate) {
                  if (data.OrderCreate.payment_url) {
                    let link = document.createElement("a");
                    link.href = data.OrderCreate.payment_url;
                    link.click();
                  }
                }
                // } else {
                //   this.clearCart();
                //   window.scrollTo(0, 0);
                //   this.$store.state._modals.push({
                //     component: AlertModal,
                //     options: {
                //       title: "Заказ успешно оформлен",
                //       message: "Вы можете посмотреть свои заказы в личном кабинете",
                //       closeBtnClass: "btn--gray",
                //       isConfirm: true,
                //       confirmBtnTitle: "Перейти",
                //       callback: () => {
                //         this.$router.push({ name: "user", params: { link: "orders" } });
                //       },
                //     },
                //   });
                // }
              })
              .catch(({ graphQLErrors }) => {
                this.loading = false;
                this.parseGqlErrors(graphQLErrors);
                this.scrollTo("recipientForm");
              });
          } else {
            this.loading = false;
            this.scrollTo("deliveryForm");
          }
        }
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
          options: {
            routeAfterLogin: {
              name: this.$router.currentRoute.name,
              params: this.$router.currentRoute.params,
              hash: this.$router.currentRoute.hash,
            },
          },
        });
      }
    },
    clearCart() {
      let ids = this.cartItems.map((i) => i.id || i.item.id);
      this.removeCartItems({ store: this.$store, apollo: this.$apollo.provider.defaultClient, ids });
    },
    /**
     * Изменение количества товаров в корзине
     * @param items
     * @returns {Promise<void>}
     */
    async countChange(items) {
      // изменение на фронте до запроса, чтобы быстрее отобразилось
      items.forEach((item) => {
        this.$store.state.auth.cart.find((c) => c.item.id === item.id).count = item.count;
      });
      // костыль, изменяем массив, чтобы вызвался watch в App.vue
      // и сохранил изменения в localStorage
      this.$store.state.auth.cart.push({});
      this.$store.state.auth.cart.pop();

      await this.getPriceDelivery();

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        if (this.isRegistered) {
          this.$store.dispatch("CART_ADD_ITEMS", {
            apollo: this.$apollo,
            variables: {
              items: items,
            },
          });
        }
      }, 500);
    },
    /**
     * Удаление товаров в корзине
     * @param ids
     * @returns {Promise<void>}
     */
    async remove(ids = []) {
      this.$store.state.auth.cart = this.$store.state.auth.cart.filter((c) =>
        c.id ? !ids.includes(c.id) : !ids.includes(c.item.id)
      );
      await this.getPriceDelivery();
      if (this.isRegistered) {
        await this.$store.dispatch("CART_DELETE_ITEMS", {
          apollo: this.$apollo,
          variables: {
            id: ids,
          },
        });
      }
    },
    /**
     * Получение стоимости доставки
     * @returns {Promise<void>}
     */
    async getPriceDelivery() {
      //   this.$apollo.provider.defaultClient
      //     .query({
      //       query: PRICE_DELIVERY,
      //       variables: {
      //         products: this.cartItems.map((c) => {
      //           return {
      //             product_id: c.item.id,
      //             count: c.count,
      //           };
      //         }),
      //         delivery_id: this.deliveryForm.delivery_type_id.value
      //           ? this.deliveryForm.delivery_type_id.value.type
      //           : this.deliveries[0]?.type,
      //       },
      //     })
      //     .then(({ data }) => {
      //       this.price_delivery = data.price_delivery;
      //     });
    },
    /**
     * Изменение способа доставки
     * @param e
     * @param item
     * @param field
     */
    changeRadio(e, item, field) {
      if (e) {
        this.deliveryForm[field].value = item;
        this.getPriceDelivery();
      }
    },
    isChecked(item, field) {
      return JSON.stringify(this.deliveryForm[field].value) === JSON.stringify(item);
    },
    /**
     * Скролл до нужного элемента
     * @param refName
     */
    scrollTo(refName) {
      window.scrollTo({ top: this.$refs[refName].offsetTop, behavior: "smooth" });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.recipientForm[key]) {
              this.recipientForm[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
  },
  metaInfo: {
    title: "Корзина",
  },
  components: {
    ProductCardCartVersionComponent,
    FormItemComponent,
    FileIconComponent,
    TextareaComponent,
    SelectComponent,
    CheckboxComponent,
    MethodComponent,
    ProductsTableComponent,
    BreadcrumbsComponent,
    RadioComponent,
    InputComponent,
    CartPageAsideComponent,
  },
};
</script>

<style lang="stylus">
.cart-page {
  width 100%
  display grid
  grid-gap 50px
  +below(680px) {
    padding 30px 0
    grid-gap 0
  }

  &__container {
    display grid
    align-items start
    gap 24px
  }

  &__title {
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    margin 0
    color: var(--body-color);
  }

  &__content {
    display grid
    grid-gap var(--gap)
    grid-template-columns 2fr minmax(350px, 1fr)
    align-items start
    +below(990px) {
      display flex
      flex-direction column-reverse
      align-items stretch
      grid-gap 30px
    }
  }

  .form__col-4 {
    +below(1100px){
      gap 16px
      flex-basis calc(100% - 16px)
    }
  }

  .form__col-6 {
    +below(540px) {
      gap 16px
      flex-basis calc(100% - 16px)
    }
  }

  &__body {
    display grid
    grid-gap 20px

    .form__row .input {
      width 100%
    }
  }

  &__products {
    position relative
    z-index 1
    background var(--white)
    border-radius var(--big-radius)
  }

  &__dropdowns {
    display grid
    grid-gap 20px
  }

  &__methods {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 20px
    +below(1300px) {
      grid-template-columns 1fr
    }
    +below(990px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(768px) {
      grid-template-columns 1fr
      grid-gap 15px
    }
  }

  &__shop {
    padding 15px
    border-radius var(--big-radius)
    background: var(--gray);
  }

  &__address {
    .radio {
      font-size 1em
      padding 13px 20px
      border-bottom 1px solid var(--gray-3-dark)
      display flex
      align-items center
    }

    &-actions {
      display flex
      justify-content flex-end
    }
  }
}
</style>
