var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-table"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Фото")]),_c('th',[_vm._v("Название")]),_c('th',{staticClass:"align-right"},[_vm._v("Стоимость")]),(!_vm.isWishlist)?_c('th',{staticClass:"align-center"},[_vm._v("Количество")]):_vm._e(),(!_vm.isWishlist)?_c('th',{staticClass:"align-right"},[_vm._v("Сумма")]):_vm._e()])]),_c('tbody',_vm._l((_vm.items),function(entity,i){return _c('tr',{key:i,staticClass:"cart-item"},[_c('td',[_c('router-link',{attrs:{"to":{
              name: 'product',
              params: {
                id: entity.item.parent_id || entity.item.id,
                link: entity.item.link,
              },
              query: {
                set_id: entity.item.parent_id ? entity.item.id : undefined,
              },
            }}},[_c('ImgComponent',{staticClass:"cart-item__img",attrs:{"img":_vm._f("item_img")(entity.item),"width":"100","height":"100"}})],1)],1),_c('td',{staticClass:"cart-item__title text-wrap"},[_c('router-link',{attrs:{"to":{
              name: 'product',
              params: {
                id: entity.item.parent_id || entity.item.id,
                link: entity.item.link,
              },
              query: {
                set_id: entity.item.parent_id ? entity.item.id : undefined,
              },
            }}},[_vm._v("\n            "+_vm._s(entity.item.title)+"\n          ")])],1),_c('td',{staticClass:"align-right"},[(entity.item.join_share && entity.item.join_share.share)?_c('span',{staticClass:"cart-item__old-price cart-item__old-sum"},[_vm._v("\n            "+_vm._s(_vm._f("formatPrice")(entity.item.price))+"\n          ")]):_vm._e(),_c('span',{staticClass:"cart-item__price"},[_vm._v("\n            "+_vm._s(_vm._f("formatPrice")(_vm._f("share_price")(entity.item.price,entity.item)))+"\n          ")])]),(!_vm.isWishlist)?_c('td',{staticClass:"align-center"},[_c('div',{staticClass:"cart-item__count"},[_c('button',{staticClass:"cart-item__count-change btn",on:{"click":function($event){return _vm.change(false, entity)}}},[_c('VuesaxIconComponent',{attrs:{"name":"minus"}})],1),_c('span',{staticClass:"cart-item__count-value"},[_vm._v(_vm._s(entity.count))]),_c('button',{staticClass:"cart-item__count-change btn",on:{"click":function($event){return _vm.change(true, entity)}}},[_c('VuesaxIconComponent',{attrs:{"name":"add"}})],1)])]):_vm._e(),(!_vm.isWishlist)?_c('td',{staticClass:"align-right"},[(entity.item.join_share && entity.item.join_share.share)?_c('span',{staticClass:"cart-item__old-sum"},[_vm._v("\n            "+_vm._s(_vm._f("formatPrice")((entity.item.price * entity.count)))+"\n          ")]):_vm._e(),_c('span',{staticClass:"cart-item__sum"},[_vm._v("\n            "+_vm._s(_vm._f("formatPrice")(_vm._f("share_price")((entity.item.price * entity.count),entity.item)))+"\n          ")])]):_vm._e(),_c('td',{staticClass:"no-padding"},[_c('button',{staticClass:"cart-item__remove btn",on:{"click":function($event){return _vm.remove(entity)}}},[_c('VuesaxIconComponent',{attrs:{"name":"add"}})],1)])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }