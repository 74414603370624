<template>
  <div v-if="data && data.item" class="product-cart">
    <div class="product-cart__content">
      <button @click="remove(data)" class="product-cart__remove btn">
        <IconComponent name="close-circle" />
      </button>
      <router-link
        :to="{
          name: 'product',
          params: {
            id: data.item.parent_id || data.item.id,
            link: data.item.link,
          },
          query: {
            set_id: data.item.parent_id ? data.item.id : undefined,
          },
        }"
      >
        <ImgComponent
          class="product-cart__img"
          :img="data.item | item_img"
          width="100"
          height="100"
          contain
        />
      </router-link>
      <div class="product-cart__info">
        <router-link
          class="product-cart__title"
          :to="{
            name: 'product',
            params: {
              id: data.item.parent_id || data.item.id,
              link: data.item.link,
            },
            query: {
              set_id: data.item.parent_id ? data.item.id : undefined,
            },
          }"
        >
          {{ data.item.title }}
        </router-link>
        <div class="product-cart__attributes">
          <div v-if="data.item.variation_one" class="product-cart__attribute">
            {{ data.item.variation_one.attribute.title }} {{ data.item.variation_one.value.value }}
          </div>
          <div v-if="data.item.variation_two" class="product-cart__attribute">
            {{ data.item.variation_two.attribute.title }} {{ item.variation_two.value.value }}
          </div>
          <div v-if="data.item.variation_three" class="product-cart__attribute">
            {{ data.item.variation_three.attribute.title }} {{ data.item.variation_three.value.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="product-cart__count">
      <button @click="change(false, data)" class="product-cart__count-change btn">
        <IconComponent name="minus-square" />
      </button>
      <span class="product-cart__count-value">{{ data.count }}</span>
      <button @click="change(true, data)" class="product-cart__count-change btn">
        <IconComponent name="add-square" />
      </button>
    </div>
    <div class="product-cart__prices">
      <span class="product-cart__price">
        {{ (data.item.price * data.count) | share_price(data.item) | formatPrice }}
      </span>
      <span
        v-if="
          (data.item.join_share && data.item.join_share.share) ||
          (data.item.promo_share && data.item.promo_share.share) ||
          (data.item.join_share_set && data.item.join_share_set.share)
        "
        class="product-cart__old-price"
      >
        {{ (data.item.price * data.count) | formatPrice }}
      </span>
      <!--      <span class="product-cart__bonus-percent">-5% с бонусной картой</span>-->
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ProductCardCartVersionComponent",
  props: {
    data: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    item() {
      return this.data.item;
    },
    item_img() {
      if (this.item.head_img) {
        return this.item.head_img;
      }
      if (this.item.images && this.item.images.length) {
        return this.item.images[0];
      }
      if (this.item.parent && this.item.parent.head_img) {
        return this.item.parent.head_img;
      }
      return null;
    },
    inFavorites() {
      return this.$store.state.auth.favorites_ids.includes(this.item.id);
    },
  },
  methods: {
    /**
     * Изменение количества товаров в корзине
     * increase == true - увеличение
     * increase == false - уменьшение
     * @param increase
     * @param data
     */
    change(increase, data) {
      let count = increase ? data.count + 1 : data.count - 1;
      if (count <= 0) {
        this.remove(data);
      } else {
        this.$emit("countChange", [{ id: data.item.id, count }]);
      }
    },
    remove(data) {
      let ids = data.id ? [data.id] : [data.item.id];
      // if (confirm("Вы точно хотите удалить выбранные товары?\nОтменить действие будет невозможно")) {
      this.$emit("remove", ids);
      // }
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.product-cart {
  width 100%
  padding 5px 15px
  display grid
  grid-template-columns 1fr 120px 160px
  align-items center
  grid-gap 15px 25px
  +below(1300px) {
    display flex
    flex-wrap wrap
    padding 15px
  }

  &:not(:last-child) {
    border-bottom 1px solid var(--bg-color)
  }

  &__content {
    display grid
    grid-template-columns 40px 80px minmax(auto, 400px)
    grid-gap 15px
    align-items center
    +below(1300px) {
      width 100%
      grid-template-columns 80px 1fr 40px
    }
  }

  &__remove {
    width 40px
    height 40px
    +below(1300px) {
      order 2
    }

    &:hover {
      background var(--bg-color)

      .icon svg path {
        fill var(--red)
      }
    }

    .icon {
      width: 24px
      height: 24px

      svg path {
        transition var(--transition)
      }
    }
  }

  &__img {
    width 80px
    height 80px
    object-fit contain
    border-radius var(--main-radius)
    background-color var(--white)

    &:hover {
      filter: brightness(.86);
    }
  }

  &__info {
    display flex
    flex-direction column
    gap: 5px
  }

  &__title {
    font-weight: 700;
    +below(540px) {
      font-size: 0.875em
      line-height: 24px;
    }
  }

  &__attributes {
    display flex
    flex-wrap wrap
    align-items center
    gap: 0 10px
  }

  &__attribute {
    padding 5px 10px
    font-size: 0.875em
    line-height: 1.42
    +below(440px) {
      padding 5px 0
    }
  }

  &__count {
    display inline-flex
    align-items center
    justify-content center
    border 1px solid var(--border-color)
    border-radius: var(--big-radius)
    overflow hidden

    > * {
      width 40px
      height 40px
      display flex
      align-items center
      justify-content center
    }

    &-change {
      transition var(--transition)
      border-radius 0

      &:hover {
        background var(--gray)
      }

      .icon {
        width 18px
        height 18px
      }
    }
  }

  &__prices {
    display flex
    flex-wrap wrap-reverse
    gap: 5px 15px
    max-width 165px
  }

  &__price {
    font-weight: 700;
    font-size: 1.375em
    line-height: 1.27
    color: var(--red);
  }

  &__old-price {
    font-size: 18px;
    line-height: 28px;
    color: var(--dark-light);
    text-decoration line-through
    text-decoration-color var(--red)
  }

  &__bonus-percent {
    font-size: 0.75em
    line-height: 1.16
    color: var(--red);
    width 100%
  }
}
</style>
