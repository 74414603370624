<template>
  <div class="method">
    <figure v-html="icon" />
    <span class="method__title">{{ title }}</span>
    <span v-if="description" class="method__description"
      >{{ description === "0" ? description : "от " + description }} ₽</span
    >
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CartWayComponent",
  components: { IconComponent },
  props: {
    title: String,
    icon: String,
    description: String,
  },
};
</script>

<style lang="stylus">
.method {
  background: var(--white);
  border: 2px solid var(--border-color)
  border-radius: var(--big-radius)
  padding 13px
  height 60px
  display flex
  align-items center
  justify-content space-between
  gap 10px
  cursor pointer
  width 100%
  transition var(--transition)
  +below(420px) {
    height 50px
  }

  svg {
    width 20px
    height 20px
  }

  &:hover {
    border-color var(--dark-light)
  }

  &--active& {
    border-color var(--main)

    .icon svg path {
      fill var(--main)
    }
  }

  .icon {
    flex-shrink 0
    width 20px
    height 20px

    svg path {
      fill var(--dark-light)
      transition var(--transition)
    }
  }

  &__title {
    flex-grow 1
    font-size 1em
  }

  &__description {
    flex-shrink 0
    margin-left: auto;
    opacity: 0.3;
    font-size: 0.875em
    line-height: 20px;
  }
}
</style>
