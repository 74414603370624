<template>
  <div class="cart-table">
    <table class="table">
      <thead>
        <tr>
          <th>Фото</th>
          <th>Название</th>
          <th class="align-right">Стоимость</th>
          <th v-if="!isWishlist" class="align-center">Количество</th>
          <th v-if="!isWishlist" class="align-right">Сумма</th>
        </tr>
      </thead>
      <tbody>
        <tr class="cart-item" v-for="(entity, i) in items" :key="i">
          <td>
            <router-link
              :to="{
                name: 'product',
                params: {
                  id: entity.item.parent_id || entity.item.id,
                  link: entity.item.link,
                },
                query: {
                  set_id: entity.item.parent_id ? entity.item.id : undefined,
                },
              }"
            >
              <ImgComponent class="cart-item__img" :img="entity.item | item_img" width="100" height="100" />
            </router-link>
          </td>
          <td class="cart-item__title text-wrap">
            <router-link
              :to="{
                name: 'product',
                params: {
                  id: entity.item.parent_id || entity.item.id,
                  link: entity.item.link,
                },
                query: {
                  set_id: entity.item.parent_id ? entity.item.id : undefined,
                },
              }"
            >
              {{ entity.item.title }}
            </router-link>
          </td>
          <td class="align-right">
            <span
              v-if="entity.item.join_share && entity.item.join_share.share"
              class="cart-item__old-price cart-item__old-sum"
            >
              {{ entity.item.price | formatPrice }}
            </span>
            <span class="cart-item__price">
              {{ entity.item.price | share_price(entity.item) | formatPrice }}
            </span>
          </td>
          <td v-if="!isWishlist" class="align-center">
            <div class="cart-item__count">
              <button @click="change(false, entity)" class="cart-item__count-change btn">
                <VuesaxIconComponent name="minus" />
              </button>
              <span class="cart-item__count-value">{{ entity.count }}</span>
              <button @click="change(true, entity)" class="cart-item__count-change btn">
                <VuesaxIconComponent name="add" />
              </button>
            </div>
          </td>
          <td v-if="!isWishlist" class="align-right">
            <span v-if="entity.item.join_share && entity.item.join_share.share" class="cart-item__old-sum">
              {{ (entity.item.price * entity.count) | formatPrice }}
            </span>
            <span class="cart-item__sum">
              {{ (entity.item.price * entity.count) | share_price(entity.item) | formatPrice }}
            </span>
          </td>
          <td class="no-padding">
            <button @click="remove(entity)" class="cart-item__remove btn">
              <VuesaxIconComponent name="add" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import VuesaxIconComponent from "components/VuesaxIconComponent.vue";

export default {
  name: "ProductsTableComponent",
  props: {
    items: Array,
    isWishlist: Boolean,
  },
  methods: {
    /**
     * Изменение количества товаров в корзине
     * increase == true - увеличение
     * increase == false - уменьшение
     * @param increase
     * @param data
     */
    change(increase, data) {
      let count = increase ? data.count + 1 : data.count - 1;
      if (count <= 0) {
        this.remove(data);
      } else {
        this.$emit("countChange", [{ id: data.item.id, count }]);
      }
    },
    remove(data) {
      let ids = data.id ? [data.id] : [data.item.id];
      if (this.isWishlist) {
        ids = [data.item.id];
      }
      if (confirm("Вы точно хотите удалить выбранные товары?\nОтменить действие будет невозможно")) {
        this.$emit("remove", ids);
      }
    },
  },
  components: { VuesaxIconComponent },
};
</script>

<style lang="stylus">
.cart-table {
  overflow-x auto
}

.cart-item {
  &:hover {
    .cart-item__count {
      border-color var(--gray)

      &-change {
        opacity 1
      }
    }
  }

  &__img {
    width 50px
    height 50px
    object-fit contain
    object-position center
  }

  &__title {
    min-width 280px
  }

  &__count {
    display inline-flex
    align-items center
    //grid-template-columns repeat(3, 35px)
    justify-content center
    border 1px solid transparent
    border-radius: var(--big-radius)
    overflow hidden

    > * {
      width 35px
      height 35px
      display flex
      align-items center
      justify-content center
    }

    &-change {
      opacity 0
      transition var(--transition)
      border-radius 0

      &:hover {
        background var(--gray)
      }

      .icon {
        width 18px
        height 18px
      }
    }

    &-value {
      font-size: 16px;
      line-height: 170%;
    }
  }

  &__remove {
    padding 7px

    &:hover .icon {
      background var(--red)

      svg path {
        fill var(--white)
      }
    }

    .icon {
      width 20px
      height 20px
      border-radius 50%
      background var(--gray)
      transform rotate(45deg)
      transition var(--transition)

      svg path {
        stroke var(--brown-light)
      }
    }
  }

  &__sum {
    font-weight 700
  }

  &__old-sum {
    display flex
    justify-content right
    font-size: 0.75em
    line-height: 110%
    color: var(--brown-light)
    text-decoration line-through
    text-decoration-color var(--brown)
  }
}
</style>
