<template>
  <div class="cart-page-aside">
    <div class="cart-page-aside__total">
      <span>Итого</span>
      <span>{{ sum | formatPrice }}</span>
    </div>
    <ul class="cart-page-aside__info">
      <li>
        <span>Товаров на сумму</span>
        <span v-if="discount">{{ (sum + discount) | formatPrice }}</span>
        <span v-else>{{ sum | formatPrice }}</span>
      </li>
      <!--      <li v-if="delivery">-->
      <!--        <span>Доставка</span>-->
      <!--        <span v-if="price_delivery === '0'"> Бесплатно </span>-->
      <!--        <span v-else>от {{ price_delivery }}</span>-->
      <!--      </li>-->
      <li v-if="discount" class="cart-page-aside__discount">
        <span>Экономия</span>
        <span>{{ discount | formatPrice }}</span>
      </li>
    </ul>
    <div class="cart-page-aside__pay">
      <button type="button" class="btn btn--lg btn--red" :disabled="!agree" @click="pay">
        <FileIconComponent v-if="loading" name="loading" />
        <template v-else>Оформить заказ</template>
      </button>
      <TermsAgreeComponent />
    </div>
    <div class="cart-page-aside__promocode">
      <InputComponent
        v-model.trim="promocode.value"
        :error="promocode.error"
        title="У меня есть промокод"
        placeholder="Вставьте код"
        icon-position="right"
        @action="checkPromocode"
        @enter="checkPromocode"
        modified
      >
        <template v-slot:icon>
          <IconComponent v-if="loadingPromocode" name="loading" />
          <IconComponent v-else name="arrow-right" />
        </template>
      </InputComponent>
      <div v-if="activePromocode" class="cart-page-aside__promocode-active">
        <span>{{ activePromocode }}</span>
        <button @click="removePromocode" type="button" class="btn">
          <IconComponent name="close-circle" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileIconComponent from "components/FileIconComponent.vue";
import TermsAgreeComponent from "components/TermsAgreeComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import SHARE_PROMOCODE from "@/graphql/queries/share_promocode.graphql";

export default {
  name: "CartPageAsideComponent",
  props: {
    delivery: Object,
    loading: Boolean,
    sum: Number,
    discount: Number,
    promocode_discount: Number,
    price_delivery: [Number, String],
  },
  data() {
    return {
      agree: true,
      loadingPromocode: false,
      showPromoField: false,
      activePromocode: undefined,
      promocode: {
        value: undefined,
        error: undefined,
      },
    };
  },
  methods: {
    pay() {
      if (this.agree) {
        this.$emit("pay");
      }
    },
    async checkPromocode() {
      if (!this.loadingPromocode && this.promocode.value) {
        this.promocode.error = undefined;
        this.loadingPromocode = true;
        await this.$apollo.provider.defaultClient
          .query({
            query: SHARE_PROMOCODE,
            variables: {
              promocode: this.promocode.value,
            },
          })
          .then(({ data }) => {
            this.loadingPromocode = false;
            if (data.share_promocode) {
              this.activePromocode = this.promocode.value;
              this.$emit("setPromocode", data.share_promocode, this.promocode.value);
              this.$notify({
                title: "Успешно",
                text: "Промокод активирован",
                type: "success",
              });
            } else {
              this.promocode.error = "Неверный промокод";
            }
          })
          .catch(() => {
            this.loadingPromocode = false;
            this.promocode.error = "Неверный промокод";
          });
      }
    },
    removePromocode() {
      this.activePromocode = undefined;
      this.$emit("removePromocode");
    },
  },
  components: {
    IconComponent,
    InputComponent,
    TermsAgreeComponent,
    FileIconComponent,
  },
};
</script>

<style lang="stylus">
.cart-page-aside {
  background: var(--white);
  border-radius: var(--big-radius);
  filter: drop-shadow(0px 15px 20px var(--black_o1));
  display grid
  align-items start
  overflow hidden
  border: 1px solid var(--gray);
  box-shadow: 0 308px 123px rgba(25, 41, 57, 0.01), 0px 173px 104px rgba(25, 41, 57, 0.05), 0px 77px 77px rgba(25, 41, 57, 0.09), 0px 19px 42px rgba(25, 41, 57, 0.1), 0px 0px 0px rgba(25, 41, 57, 0.1);
  max-width 500px
  +above(991px) {
    position sticky
    top calc(var(--header-main-height) + 24px)
  }

  &__total {
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid var(--gray)
    width 100%
    padding 20px
    background var(--gray_o5)

    span {
      font-weight: 700;
      font-size: 1.375em
      line-height: 115%
      display: flex;
      align-items: center;
      color: var(--body-color);
    }
  }

  &__info {
    display grid
    width 100%

    li {
      display flex
      width 100%
      justify-content space-between
      padding 10px 20px

      &.cart-page-aside__discount {
        background var(--green-o05)
      }

      span {
        display: flex;
        align-items: center;
        color: var(--black);
      }
    }
  }

  &__pay {
    display: grid;
    grid-gap 10px
    width 100%
    padding 20px
  }

  &__promocode {
    display grid
    grid-gap 20px
    padding 10px 20px
    border-top 1px solid var(--gray)

    .input__action {
      transition var(--transition)
      &:hover {
        background var(--gray)
      }

      .icon,
      .file-icon {
        width: 20px
        height: 20px

        svg circle {
          stroke var(--dark)
        }
      }
    }

    &-active {
      display flex
      align-items center
      justify-content space-between
      background: var(--gray);
      border-radius: var(--small-radius);
      padding: 5px 15px

      span {
        color var(--blue)
      }

      .btn {
        padding 5px

        svg path {
          transition var(--transition)
        }

        &:hover {
          background var(--gray)

          svg path {
            fill var(--red)
          }
        }
      }
    }
  }
}
</style>
